import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import { StoreLocator } from '/src/components/store_locator'
import { formatStores } from '/src/components/commerce/store/util'

const StoreLocatorPage = ({ data }) => {
  const { edges } = data.allMcStoreMcStore

  const stores = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: formatStores(edges.sort((x, y) => {
        const nameX = x.node.name.split(" ")[0]
        const nameY = y.node.name.split(" ")[0]
  
        return nameY.localeCompare(nameX)
      }))
    }
  }, [])

  return (
    <Layout mainContainer={{fluid: "xxl"}}>
      <Row>
        <Col sm={12} className="p-0">
          <StoreLocator stores={stores} />
        </Col>
      </Row>
    </Layout>
  )
}

export default StoreLocatorPage

export const Head = () => (
  <>
    <title>Store Locator | Orrcon Steel</title>
    <meta name="description" content="Orrcon Steel is a leading Australian distributor and manufacturer of steel, tube and pipe. Our extensive product range covers RHS, SHS & CHS structural tubular steel, hot rolled structural steel and a variety of fencing, roofing and building accessories." />
    <meta name="og:description" property="og:description" content="Your One-Stop Shop for Effortless Ordering of Premium Steel Products. Buy online or call us today. At Orrcon Steel, we'll see it through" />
    <meta property="og:image" content="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/og_orrcon.png" />
  </>
)

export const query = graphql`
{
  allMcStoreMcStore {
    edges {
      node {
        name
        drupal_id
        field_mc_location {
          lat
          lon
        }
        dsap_plant_id
        field_mc_store_opening_times
        field_mc_store_email
        field_mc_store_description
        field_mc_store_delivery_estimate
        field_mc_store_address {
          address_line1
          address_line2
          country_code
          administrative_area
          locality
          postal_code
        }
        field_mc_store_phone_number
        field_mc_store_pickup_estimate
      }
    }
  }
}
`